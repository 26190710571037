<template>
  <div class="Authority">
    <Header title="合作项目" index="首页" titleOne="合同管理" beforeTitle="合作项目" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select
            placeholder="请选择项目类型"
            v-model="projectParams.product_type"
            @change="getproductType()"
          >
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input
            v-model="projectParams.keywords"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="project.data"
        row-key="id"
        style="width: 100%"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
        </el-table-column>
        <el-table-column prop="contract_name" label="合同名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.contract_name ? scope.row.contract_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="product_name" label="项目名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.product_name ? scope.row.product_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="project_type" label="项目类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.project_type ? scope.row.project_type : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="code" label="项目编号" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.code ? scope.row.code : "-" }}</template>
        </el-table-column>
        <el-table-column prop="bussiness_manager" label="业务经理" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.bussiness_manager ? scope.row.bussiness_manager : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="渠道商" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.channel ? scope.row.channel : "-" }}</template>
        </el-table-column>
        <el-table-column prop="status" label="项目" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="state wtg" v-if="scope.row.status == 0">
              <i></i>未开展
            </span>
            <span style="color:#5CC038" class="state" v-if="scope.row.status == 1">
              <i></i>已流转
            </span>
            <span style="color:#5CC038" class="state" v-if="scope.row.status == 2">
              <i></i>已分配
            </span>
            <span class="state ytj" v-if="scope.row.status == 3">
              <i></i>开展中
            </span>
            <span class="state ytg" v-if="scope.row.status == 4">
              <i></i>已完结
            </span>
            <span class="state wtg" v-if="scope.row.status == 5">
              <i></i>被退回
            </span>
            <span class="state wtg" v-if="scope.row.status == 6">
              <i></i>已撤回
            </span>
            <span class="state wtg" v-if="scope.row.status == 7">
              <i></i>已终止
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="receipt" label="开票" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <!-- <span class="state" v-if="scope.row.receipt == 0">
              <i></i>-
            </span>-->
            <span class="state" v-if="scope.row.receipt == 1">-</span>
            <span style="color:#FFBE2C" class="state" v-if="scope.row.receipt == 2">申请中</span>
            <span style="color:#5CC038" class="state ytj" v-if="scope.row.receipt == 3">已开票</span>
          </template>
        </el-table-column>
        <el-table-column prop="refund" label="退款" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="state" v-if="scope.row.refund == 1">
              <i></i>-
            </span>
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.refund_reason"
              placement="top-start"
            >
              <span class="state wtg" v-if="scope.row.refund == 2">
                <i></i>已拒绝
              </span>
            </el-tooltip>
            <span style="color:#FFBE2C" class="state" v-if="scope.row.refund == 3">
              <i></i>申请中
            </span>
            <span class="state ytg" v-if="scope.row.refund == 5">
              <i></i>已退款
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="560px">
          <template slot-scope="scope" class="caozuo">
            <div class="button_box">
              <el-button
                class="edit"
                size="mini"
                @click="handleEdit(scope.row.id)"
                v-if="privilige_list.contract_project.includes('edit')"
              >编辑</el-button>
              <el-button
                class="edit"
                size="mini"
                @click="handleInvoicing(scope.row.id)"
                v-if="privilige_list.contract_project.includes('invoice') && scope.row.status != 7"
              >申请开票</el-button>
              <el-button
                class="edit"
                size="mini"
                @click="Refund(scope.row.id)"
                v-if="privilige_list.contract_project.includes('refund') && scope.row.status != 7"
              >申请退款</el-button>
              <el-button
                v-if="scope.row.is_additional == 1 && scope.row.status != 7"
                class="edit"
                size="mini"
                @click="handleAddtoo(scope.row.id)"
              >添加附加</el-button>
              <el-button
                v-if="scope.row.status != 7"
                class="edit"
                size="mini"
                @click="handleEnd(scope.row.id)"
              >终止项目</el-button>
              <el-button
                v-if="scope.row.status == 7"
                class="edit"
                size="mini"
                @click="handleEnds(scope.row.id)"
              >项目重启</el-button>
              <el-button
                v-if="scope.row.p_id > 0"
                class="edit"
                size="mini"
                @click="handleAssign(scope.row.id)"
              >去分配</el-button>
              <!-- <el-button
              class="edit"
              size="mini"
              @click="handleInvoicing(scope.row.id)"
              v-if="scope.row.invoicing == false && scope.row.receipt == 2"
            >开票中</el-button> 
            <el-button
              class="already"
              size="mini"
              v-if="scope.row.invoicing == false && scope.row.receipt == 3"
              >已开票</el-button>-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.refund_reason"
                placement="top-start"
              >
                <el-button
                  class="edit"
                  size="mini"
                  @click="Refund(scope.row.id)"
                  slot="reference"
                  v-if="scope.row.refund_option == true && scope.row.refund == 2"
                >拒绝退款</el-button>
              </el-tooltip>
              <!--<el-button
              class="edit"
              size="mini"
              v-if="scope.row.refund_option == false && scope.row.refund == 3"
            >申请退款</el-button>
             <el-button
              class="edit"
              size="mini"
              v-if="scope.row.refund_option == false && scope.row.refund == 4"
            >同意退款</el-button>
            <el-button
              class="edit"
              size="mini"
              v-if="scope.row.refund_option == false && scope.row.refund == 5"
              >已退款</el-button>-->
              <el-button
                v-if="scope.row.status !== 0"
                class="edit"
                size="mini"
                @click="handleDetail(scope.row.id)"
              >详情</el-button>
              <el-button
                class="del"
                size="mini"
                @click="handleDelete(scope.row.id)"
                v-if="privilige_list.contract_project.includes('delete')"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="project.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="projectParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="projectParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="project.total"
        ></el-pagination>
      </div>
      <el-dialog title="申请退款" :visible.sync="dialogFormVisible" width="300px" height="400px">
        <el-form :model="form" :rules="formRule" ref="formRef" :label-position="labelPosition">
          <el-form-item label="退款金额">
            <el-input placeholder="请输入退款金额" v-model="form.refund_amount" type="text"></el-input>
          </el-form-item>
          <el-form-item label="申请原因">
            <el-input placeholder="请输入退款原因" v-model="form.reason" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" :loading="btnLoading" @click="saveRefund()">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="项目分配" :visible.sync="dialogAssign" width="300px" height="400px">
        <el-form
          :model="flowform"
          :rules="formRuleAssign"
          ref="formRef"
          :label-position="labelPosition"
        >
          <el-form-item label="部门" prop="role">
            <el-select
              v-model="flowform.role"
              placeholder="请选择部门"
              @change="getStaff"
              style="width: 100%"
            >
              <el-option v-for="item in role" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分配人" prop="member">
            <el-select
              v-model="flowform.member"
              placeholder="请选择人员"
              style="width: 100%"
              @change="getMemberId"
            >
              <el-option
                v-for="item in member"
                :key="item.id"
                :label="item.true_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveAssign()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      btnLoading: false,//退款提交按钮动画
      ifEnd: true,
      opt: { id: "0", name: "全部" },
      loading: true,
      labelPosition: "top",
      options: [],
      itemId: "",
      projectTotal: 0,
      projectParams: {
        page: 1,
        limit: 10,
        keywords: "",
        product_type: "",
        order: "id desc",
      },
      visible: false,
      dialogFormVisible: false,
      dialogAssign: false,//项目 去分配 弹框
      form: {
        reason: "",
        refund_amount: '',
      },
      formRuleAssign: {},
      formRule: {},
      privilige_list: [],//权限列表
      flowform: {
        role: "",
        member: "",
      },//项目分配v-model
      member: [],//分配人员
      role: [],//部门选择
      assign_id: '',
    };
  },
  created () {
    this.getDepartment()
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    console.log(this.privilige_list.contract_project, '合作项目权限列表')
  },
  activated () {
    // this.handleSearch();
    this.projectParams
    this.$store.dispatch("getProjectList", this.projectParams);
    // this.$store.dispatch("getProjectList", this.projectParams);
    console.log(this.projectParams, '合作项目参数')
  },
  mounted () {
    this.getproductType();
  },
  methods: {
    // 终止项目
    handleEnd (id) {
      console.log(id)
      this.$confirm('确定对此项目进行终止?再次点击已终止可恢复！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.ifEnd = false
        this.axios.post('/api/contract/stop_contract_project', { contract_project_id: id }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message)
            this.$store.dispatch("getProjectList", this.projectParams);
          } else if (res.code == 0) {
            this.$message.error(res.message)
            this.$store.dispatch("getProjectList", this.projectParams);
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    getDepartment () {
      this.axios.get("/api/organization/select_organization").then((res) => {
        this.role = res.data;
        this.auditOptions = res.data
      });
    },
    // 部门选择
    getStaff (id) {
      this.axios
        .get("/api/organization/get_organization_assign", {
          params: {
            organization_id: id,
          },
        })
        .then((res) => {
          console.log(res);
          this.member = res.data;
        });
    },
    saveAssign () {
      this.axios.post('/api/contract/assign_contract_project', { contract_project_id: this.assign_id, tasker_id: this.flowform.member }).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.message)
          this.dialogAssign = false
          this.$store.dispatch("getProjectList", this.projectParams);
        } else if (res.code == 1) {
          this.$message.success(res.message)
          this.dialogAssign = false
          this.$store.dispatch("getProjectList", this.projectParams);
        }
      })
    },
    // 项目重启
    handleEnds (id) {
      this.axios.post('/api/contract/restart_contract_project', { contract_project_id: id }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message)
          this.$store.dispatch("getProjectList", this.projectParams);
        } else if (res.code == 0) {
          this.$message.error(res.message)
          this.$store.dispatch("getProjectList", this.projectParams);
        }
      })
    },
    // 添加附加
    handleAssign (id) {
      this.assign_id = id
      this.dialogAssign = true
    },
    handleAddtoo (id) {
      this.$router.push({ path: '/contract/edit', query: { id: id, status: 'additional' } })
    },
    handleSizeChange (val) {
      this.projectParams.limit = val;
      this.$store.dispatch("getProjectList", this.projectParams);
    },
    handleCurrentChange (val) {
      this.projectParams.page = val;
      this.$store.dispatch("getProjectList", { ...this.projectParams });
    },
    getproductType () {
      this.axios.get("/api/policy/select_product_type").then((res) => {
        this.options = res.data;
        this.options.unshift(this.opt);
        this.$store.dispatch("getProjectList", { ...this.projectParams, page: '', limit: '' });
      });
    },
    Refund (id) {
      this.dialogFormVisible = true;
      this.form.id = id;
    },
    saveRefund () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          this.axios
            .post("/api/contract/refund", {
              id: this.form.id,
              refund_reason: this.form.reason,
              refund_amount: +this.form.refund_amount
            })
            .then((res) => {
              this.btnLoading = false
              this.$message.success(res.message);
              this.$store.dispatch("getProjectList", this.projectParams);
              this.reload();
            })
            .catch((res) => {
              this.btnLoading = true
              console.log("err:", res);
            });
          this.dialogFormVisible = false;
        } else {
          console.log("输入错误");
        }
      });
    },
    handleEdit (id) {
      this.$router.push("/contract/edit?id=" + id);
    },
    handleInvoicing (id) {
      this.$router.push("/contract/invoic?id=" + id);
    },
    handleDetail (id) {
      console.log(id, '合作项目详情id')
      this.$router.push({ path: '/contract/con_detail', query: { id: id } })
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/contract/delete_project", {
            params: {
              id: id,
            },
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.reload();
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSearch () {
      this.$store.dispatch("getProjectList", { ...this.projectParams, page: '', limit: '' });
      this.projectParams.page = 1
    },
  },
  computed: {
    ...mapState(["project"]),
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped">
.wtg {
  color: #ff6f7a;
  font-weight: bold;
}

.ytg {
  color: #cacddf;
  font-weight: bold;
}

.ytj {
  color: #ffbe2c;
  font-weight: bold;
}
span.edit {
  background: #f2f3f9 !important;
  border-radius: 2px;
  color: #0f38ff !important;
  border: none;
  font-weight: bold;
  display: inline-block;
  padding: 1px 10px;
  font-size: 12px;
  margin-left: 10px;
}
.already {
  color: #cacddf;
  background: #f2f3f9 !important;
  border: none;
}
.state {
  font-weight: bold;
}
.el-progress__text {
  color: #0f38ff;
  font-weight: bold;
  font-size: 14px;
}
div/deep/.el-dialog__title {
  font-weight: bold !important;
}
/* .button_box {
  display: flex;
  overflow: auto;
} */
</style>
